<template>
  <div id="Eastern_Seat_Selection_fushu">
    <top></top>
    <div class="Eastern_Seat_Selection_body_div">
      <div class="Eastern_Seat_Selection_body">
        <div class="Eastern_Seat_Selection_input_body">
          <div class="Eastern_Seat_Selection_input_div">
            <span>乘机人姓名：</span>
            <input
              type="text"
              v-model="xing"
              placeholder="格式：张三@王五@李四"
            />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>乘机人票号：</span>
            <input
              type="text"
              v-model="piao"
              placeholder="格式：第一个乘机人票号"
            />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>选择座位：</span>
            <input type="text" v-model="zuo" placeholder="格式: 36A@36B@36C" />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>航班下标：</span>
            <input type="text" v-model="xia" placeholder="格式: 从0以此类推" />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>东航账号：</span>
            <input type="text" v-model="admin" placeholder="东航账号" />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>东航密码：</span>
            <input type="text" v-model="pwd" placeholder="东航密码" />
          </div>
          <div class="Eastern_Seat_Selection_input_div">
            <span>东航支付密码：</span>
            <input type="text" v-model="zhifumima" placeholder="东航支付密码" />
          </div>
        </div>
        <div class="Eastern_Seat_Selection_xuanzuo">
          <button @click="xuanzuo">选座</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import top from "../components/BodyComponents.vue";
export default {
  name: "Eastern_Seat_Selection_fushu",
  data() {
    return {
      xing: "",
      piao: "",
      zuo: "",
      xia: "",
      admin: "",
      pwd: "",
      zhifumima: "",
    };
  },
  components: {
    top,
  },
  mounted() {},
  methods: {
    xuanzuo() {
      let arr = [
        this.xing,
        this.piao,
        this.zuo,
        this.xia,
        this.admin,
        this.pwd,
        this.zhifumima,
      ];
      if (arr.includes("")) {
        this.$message.error("信息填写不完整");
        return false;
      }
      this.$https(
        "lu/mu",
        "pageFullName=" +
          this.xing +
          "&xno=" +
          this.piao +
          "&zuo=" +
          this.zuo +
          "&xiabiao=" +
          this.xia +
          "&account=" +
          this.admin +
          "&walletPwd=" +
          this.zhifumima +
          "&accountPwd=" +
          this.pwd,
          'post'
      )
    },
  },
};
</script>


<style scoped>
</style>